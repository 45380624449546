import React, { useEffect, useState } from "react";
import Button from "../Button";
import Input from "./Input";
import dayjs from "dayjs";

var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");

dayjs.extend(utc);
dayjs.extend(timezone);

export default function Step2({
  onClick,
  state,
  appointments,
  handleChange,
  saving,
}) {
  const [typeName, setTypeName] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");

  useEffect(() => {
    if (state.appointmentTypeID) {
      const filter = appointments.filter(
        (i) => i.id === Number(state.appointmentTypeID)
      );
      setTypeName(filter[0].name);
    }
    if (state.datetime) {
      setDate(dayjs(state.datetime).tz(state.timezone).format("MMM D"));
      setTime(dayjs(state.datetime).tz(state.timezone).format("hh:mm A"));
    }
  }, [state]);

  return (
    <>
      <h1 className="common-heading">{typeName}</h1>
      <p>
        25 Minutes - {date}th - {time}
      </p>
      <div className="ap-form">
        <div className="inline-flex">
          <Input
            type={"text"}
            placeholder="First Name"
            name="firstName"
            onChange={handleChange}
          />
          <Input
            type={"text"}
            placeholder="Last Name"
            name="lastName"
            onChange={handleChange}
          />
        </div>
        <Input
          type={"email"}
          placeholder="Email Address"
          name={"email"}
          onChange={handleChange}
        />
        <Input
          type={"text"}
          placeholder="Phone Number"
          name="phone"
          onChange={handleChange}
        />
        <Input
          type={"text"}
          placeholder="Notes (Optional)"
          name="notes"
          onChange={handleChange}
        />
        {/* <label className="zone-select">
          <select name="timezone" id="timezone" className="common-heading">
            <option value={""}>Topic of conversation ( Optional )</option>
          </select>
          <svg
            width="10"
            height="6"
            viewBox="0 0 10 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 1L5 5L1 1"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </label> */}
        <Button
          text={saving ? "Saving..." : "Complete Appointment"}
          disable={saving}
          onClick={() => onClick(100)}
        />
      </div>
    </>
  );
}
