import React, { useEffect, useState } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { Link as LinkScroll } from "react-scroll";
import Appointment from "../Appointment";

export default function Popup({ onClose, value, data }) {
  const [appointmentPopup, setAppointmentPopup] = useState(false);
  const [appointmentPopupState, setAppointmentPopupState] = useState("");

  useEffect(() => {
    sessionStorage.setItem("appointmentPopup", false);
  }, []);

  useEffect(() => {
    const isOpened = JSON.parse(sessionStorage.getItem("appointmentPopup"))
      ? "OPENED"
      : "CLOSED";
    setAppointmentPopupState(isOpened);
    if (value && isOpened === "CLOSED") {
      document.getElementById("popup").style.visibility = "visible";
      document.getElementById("popup").style.opacity = "1";
    } else {
      document.getElementById("popup").style.opacity = "0";
      setTimeout(() => {
        document.getElementById("popup").style.visibility = "hidden";
      }, 500);
    }
  }, [value]);

  function handleClick(event) {
    event.preventDefault();
    sessionStorage.setItem("appointmentPopup", true);
    setAppointmentPopup(!appointmentPopup);
  }

  function handleClose(event) {
    event.preventDefault();
    sessionStorage.removeItem("appointmentPopup");
    setAppointmentPopup(false);
  }
  return (
    <div
      className={`fixed top-0 transition-opacity duration-700 ${
        value ? appointmentPopupState === "CLOSED" && "visible" : "hidden"
      } bottom-0 left-0 right-0 bg-black/30 z-[9999] backdrop-blur-[4.5px]`}
      id="popup"
      role="button"
      onClick={onClose}
    >
      <div className="absolute blur-xl top-0 left-0 right-0 bottom-0" />
      <div className="flex h-full w-full justify-center items-center z-[100] px-2">
        <div
          className="relative w-[949px] rounded-tl rounded-tr rounded-bl-[31px] rounded-br-[31px] bg-[#f5f5f5] dark:bg-[#111215] after:contents-[''] after:absolute after:-inset-[2px]
            after:rounded-tl after:rounded-tr after:rounded-bl-[31px] after:rounded-br-[31px] after:-z-[1] hero-bg"
        >
          <span
            className="absolute z-50 right-3 top-2 p-1 cursor-pointer text-4xl text-white"
            onClick={onClose}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </span>
          <div>
            <GatsbyImage
              image={data?.popupImage?.asset?.gatsbyImageData}
              placeholder="blurred"
              alt="welcome"
              className="rounded-tl rounded-tr max-h-[450px] w-full"
            />
            <div
              className="w-full h-[2px]"
              style={{
                background:
                  "linear-gradient(to right, #12c2e9, #c471ed, #f64f59)",
              }}
            />
          </div>
          <div className="py-[29px] px-[44px] relative">
            <div className="relative">
              <p className="font-primary leading-[29px] text-base sm:text-[20px] font-semibold mb-3">
                {data?.title}
              </p>
              <p className="text-xs sm:text-base leading-6 font-poppins">
                {data?.description}
              </p>
              {data?.linkType === "slug" && (
                <div className="pt-5">
                  {" "}
                  <div
                    className="relative inline-block rounded-[5px] p-[1px]"
                    style={{
                      background:
                        "linear-gradient(to right, #12c2e9, #c471ed, #f64f59)",
                    }}
                  >
                    <Link
                      className="inline-block rounded-[5px] py-[15px] px-[35px] text-[13px] button-gradient transition-all duration-200 ease-in"
                      to={`/${data.slug.current}`}
                    >
                      <span className="button-text">{data.buttonText}</span>
                    </Link>
                  </div>
                </div>
              )}{" "}
              {data?.linkType === "url" && (
                <div className="pt-5">
                  <div
                    className="relative inline-block rounded-[5px] p-[1px]"
                    style={{
                      background:
                        "linear-gradient(to right, #12c2e9, #c471ed, #f64f59)",
                    }}
                  >
                    <a
                      href={data.url}
                      target="_blank"
                      className="inline-block rounded-[5px] py-[15px] px-[35px] text-[13px] button-gradient transition-all duration-200 ease-in"
                    >
                      <span className="button-text">{data.buttonText}</span>
                    </a>
                  </div>
                </div>
              )}
              {data?.linkType === "sectionId" && (
                <div className="pt-5">
                  <div
                    className="relative inline-block rounded-[5px] p-[1px]"
                    style={{
                      background:
                        "linear-gradient(to right, #12c2e9, #c471ed, #f64f59)",
                    }}
                  >
                    <LinkScroll
                      to={data.sectionId}
                      smooth
                      duration={300}
                      onClick={onClose}
                      className="inline-block rounded-[5px] py-[15px] px-[35px] text-[13px] button-gradient transition-all duration-200 ease-in"
                    >
                      <span className="button-text">{data.buttonText}</span>
                    </LinkScroll>
                  </div>
                </div>
              )}
              {data?.linkType === "appointmentPopup" && (
                <div className="pt-5">
                  {" "}
                  <div
                    className="relative inline-block rounded-[5px] p-[1px]"
                    style={{
                      background:
                        "linear-gradient(to right, #12c2e9, #c471ed, #f64f59)",
                    }}
                  >
                    <button
                      className="inline-block rounded-[5px] py-[15px] px-[35px] text-[13px] button-gradient transition-all duration-200 ease-in"
                      onClick={handleClick}
                    >
                      <span className="button-text">{data.buttonText}</span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>{" "}
      {appointmentPopup && <Appointment closeModal={handleClose} />}{" "}
    </div>
  );
}
